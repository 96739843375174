import * as React from "react"
import styled from "styled-components"
import { AFooter } from "../styles/Primitives"
import { base } from "../styles/ColorStyles"

const Footer = styled.footer`
  /* max-width: 832px; */
  margin: 0 auto;
`
 
const Wrapper = styled.div`
 display: flex;
  justify-content: space-between;
  padding: 2rem 1rem 3rem 1rem;
  @media (max-width: 1200px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

const LeftFlex = styled.div`
  display: flex;
  gap: 1.5rem;
`


const GlobalFooter = () => (
  <Footer>
    <Wrapper>
        <LeftFlex>
          <AFooter href="https://twitter.com/_atharvapatil" target="_blank">
            Twitter
          </AFooter>
          <AFooter
            href="https://www.linkedin.com/in/atharvaabhaypatil/"
            target="_blank"
          >
            LinkedIn
          </AFooter>
          <AFooter href="https://jabberwocky1871.netlify.app/" target="_blank">
            Bookshelf
          </AFooter>
        </LeftFlex>
        <AFooter href="mailto:atharvaabhaypatil@gmail.com" target="_blank">
          Say Hello
        </AFooter>
    </Wrapper>
  </Footer>
)

export default GlobalFooter
