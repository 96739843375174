import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { base } from "../styles/ColorStyles"

const Nav = styled.nav`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  /* border-left: 1px dashed ${base.border};
  border-right: 1px dashed ${base.border};
  border-bottom: 1px dashed ${base.border}; */
  max-width: 1232px;
  margin: 0 auto;
  @media (max-width: 1136px) {
    padding: 1rem 1rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0.5rem;
  gap: 0.25rem;
  @media (max-width: 500px) {
    justify-content: center;
    gap: 1rem;
  }
`

const StyledLink = styled(Link)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  color: ${base.secondarytext};

  &:hover {
    background: none;
    text-decoration: none;
  }

  @media (max-width: 656px) {
    font-size: 0.875rem;
  }
`
const LinkWrapper = styled.span`
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid transparent;
  transition: all 0.1s ease-in-out;
  

  &:hover {
    border-radius: 2px;
    color: ${base.actionactive};
    transition: all 0.25s ease-in-out;
    background: ${base.hover};
    /* box-shadow: 0px 1px 2px black; */
  }
`

export default function GlobalNavigation() {
  return (
    <Nav>
      <FlexContainer>
        <StyledLink to="/" id="home">
          <LinkWrapper>Work</LinkWrapper>
        </StyledLink>
        <StyledLink to="/play" id="play">
          <LinkWrapper>Side Quests</LinkWrapper>
        </StyledLink>
        <StyledLink to="/about" id="about">
          <LinkWrapper>About</LinkWrapper>
        </StyledLink>
        <StyledLink to="/writing" id="blog">
          <LinkWrapper>Writing</LinkWrapper>
        </StyledLink>
      </FlexContainer>
    </Nav>
  )
}

//
