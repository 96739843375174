import * as React from "react"
import styled from "styled-components"
import { H5 } from "../styles/Primitives"
import JSONData from "../data/prototypes.json"
import ProjectCard from "./ProjectCard"
import { base } from "../styles/ColorStyles"

export default function ProjectList() {

  return (
      <SectionWrapper>
        {/* Artificial Intelligence */}
        <ItemThreeColumn style={{paddingTop: "0"}}>
          <ImageWrapper> 
            <H5>Machine Learning</H5>
          </ImageWrapper>

          <TextContent>
            <ProjectCard
                            Title="Point A Sketch" 
                            Subtitle="Recreating the classic Etch A Sketch by pointing a finger in different directions" 
                            Link="https://atharvapatil.github.io/handposer/" 
            />
            <ProjectCard
                            Title="Punchy" 
                            Subtitle="A tinyML powered personal boxing trainer built using an Arduino" 
                            Link="/work/punchy" 
            />
            
            <ProjectCard
                            Title="Don’t touch your face" 
                            Subtitle="A web app to alert when you touch your face. Built with teachable machine" 
                            Link="https://atharvapatil.github.io/corona-go/" 
            />
            <ProjectCard
                            Title="Sit straight" 
                            Subtitle="A posture tracker that blurs out youtube videos if you’re not sitting straight" 
                            Link="https://atharvapatil.github.io/teachable-browser/" 
            />
            <ProjectCard
                            Title="Doctor who style transfer" 
                            Subtitle="Apply the style of “Roars of our Stars” fan art to yourself via webcam" 
                            Link="https://atharvapatil.github.io/doctor-who-style/" 
            />
          </TextContent>
        </ItemThreeColumn>

         <ItemThreeColumn>
          <ImageWrapper> 
            <H5>Web Development</H5>
          </ImageWrapper>

          <TextContent>
            <ProjectCard    Title="Patil's Library" 
                            Subtitle="Website listing all books & data visualization of all books I’ve read since 2013" 
                            Link="https://jabberwocky1871.netlify.app/" 
            />
            <ProjectCard    Title="Zoom background generator" 
                            Subtitle="Internal site for Atlas AI employees to create branded zoom backgrounds" 
                            Link="https://twitter.com/_atharvapatil/status/1509533514923917314" 
            />
            <ProjectCard    Title="Thursday night in" 
                            Subtitle="Explore random alcoholic/non-alcoholic cocktail recipes" 
                            Link="https://atharvapatil.github.io/thursday-night-in" 
            />
          </TextContent>
        </ItemThreeColumn>


        <ItemThreeColumn>
          <ImageWrapper> 
            <H5>Physical Computing</H5>
          </ImageWrapper>

          <TextContent>
            <ProjectCard    Title="Mystery Box" 
                            Subtitle="A nostalgic puzzle based arcade game to push the limits of play" 
                            Link="https://atharvapatil.github.io/thebox" 
            />
            <ProjectCard
                            Title="Punchy" 
                            Subtitle="A tinyML powered personal boxing trainer built using an Arduino" 
                            Link="/work/punchy" 
            />
            <ProjectCard    Title="Playcook" 
                            Subtitle="A smart apron and iOS app to control cooking video playback" 
                            Link="https://www.thatworkedyesterday.com/playcook" 
            />
          </TextContent>
          
        </ItemThreeColumn>

        <ItemThreeColumn style={{paddingTop: "0", borderBottom: "1px solid transparent"}}>
          <ImageWrapper> 
            <H5>Miscellaneous</H5>
          </ImageWrapper>

          <TextContent>
            <ProjectCard    Title="3D Pixel Art" 
                            Subtitle="Early stages of learning blender. Re-created some iconic pixel art" 
                            Link="https://twitter.com/_atharvapatil/status/1310001991532376065" 
            />
            <ProjectCard    Title="Minion music player" 
                            Subtitle="Play different minion sounds as you move your head across" 
                            Link="https://atharvapatil.github.io/minionfun" 
            />
          </TextContent>
        </ItemThreeColumn>

      </SectionWrapper>

  )
}

const ItemThreeColumn = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px dashed ${base.border};

   @media (max-width: 900px) {
   grid-template-columns: 1fr;
   gap: 1rem;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (max-width: 900px) {
   grid-column: 1 / span 1;
  }
`

const SectionWrapper = styled.section`
  display: grid;
  grid-template-columns: 1;
  gap: 1rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  padding-bottom: 2rem;
  padding-top: 4rem;
`

const ImageWrapper = styled.div`
  margin-top: 1.25rem;
`

