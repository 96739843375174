import * as React from "react"
import styled from "styled-components"
import { base} from "../styles/ColorStyles"
import {  H1, Pmono } from "../styles/Primitives"

export default function ProjectAbout() {

  return (
    <section>
      <Wrapper>
        <Pmono>Side quests of a curious nerd 🤓</Pmono>
        <H1>Experiments in design, code, AI & things yet to come...</H1>
      </Wrapper>
    </section>
  )
}

const Wrapper = styled.div`
  padding: 7rem 1rem 4rem 1rem;
  margin: 0;
  border-bottom: 1px dashed ${base.border};
`