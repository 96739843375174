import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectAbout from "../components/project/project-about"
import ProjectList from "../components/project/ProjectList"

export default function Play() {
  return (
    <Layout>
      <Seo title="Play | Atharva Patil" />
      <ProjectAbout />
      <ProjectList />
    </Layout>
  )
}
