import * as React from "react"
import { H6, PS } from "../styles/Primitives"
import { base } from "../styles/ColorStyles"
import styled from "styled-components"

const FlexWrapper = styled.div`
  display: inline-flex;
  align-items: start;
  flex-direction: column;
  gap: 0.125rem;
  transition: all 0.15s ease-in-out;

  :hover {
    transition: all 0.3s ease-in-out;
  }
`

const SqLink = styled.a`
  text-decoration: none;

  :hover {
    
    ${H6} {
      color: ${base.actiondark};
    }

    ${PS} {
      color: ${base.actiondark};
    }
  }
`

const ProjectWrapper = styled.div`
  padding: 1.25rem 0.5rem;
  background: rbga(0, 0, 0, 0);
  transition: all 0.15s ease-in-out;

  :hover {
    transition: all 0.15s ease-in-out;
    background: rgba(37, 99, 235, 0.04);
  }

  @media (max-width: 650px) {
    padding: 1.25rem 0.5rem;

    svg{
      opacity: 0.2;
    }
  }
`

const Wrapper = styled.div`
   border-top: 1px dashed ${base.border};

  :first-child {
    border: none;
}

`
export default function ProjectCard(props) {
  return (
    <Wrapper>
      <SqLink href={props.Link} target="_blank" >
         <ProjectWrapper>
        <FlexWrapper>
          <H6>
            {props.Title}
          </H6>  
          <PS>
            {props.Subtitle} 
          </PS>
        </FlexWrapper>
        </ProjectWrapper>
      </SqLink>
      </Wrapper>
  )
}
