import * as React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { base } from "./styles/ColorStyles"
import GlobalNavigation from "./global-nav/global-nav"
import GlobalFooter from "./footer/footer"
import "./layout.css"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 832px; 
   margin: 0 auto;
`


const LayoutWrapper = styled.div`
  margin: 0 auto;
  /* padding: 0 1rem; */
  /* border-left: 1px dashed ${base.border};
  border-right: 1px dashed ${base.border}; */
  @media (max-width: 1136px) {
    padding: 0 1rem;
  }

  @media (max-width: 650px) {
    padding: 0 0.25rem;
  }
`

export default function Layout(props) {
  return (
    <Wrapper>
      <GlobalNavigation />
      <main>
        <LayoutWrapper>
          <main>{props.children}</main>
        </LayoutWrapper>
      </main>
      <GlobalFooter />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//         description
//         author
//       }
//     }
//   }
// `)
